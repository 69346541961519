<template>
  <div
    class="fill-height"
  >
    <v-row
      no-gutters
      class="fill-height"
    >
      <v-col
        cols="6"
        class="fill-height"
      >
        <slot name="map" />
      </v-col>
      <v-col
        cols="6"
        class="fill-height"
      >
        <v-card
          tile
          class="fill-height fill-height"
          :loading="loading"
        >
          <div
            v-if="stations && stations.length"
            class="fill-height fill-height d-flex flex-column"
          >
            <v-breadcrumbs
              :items="breadcrumb"
              class="font-weight-bold"
            >
              <template v-slot:divider>
                <v-icon color="primary">
                  mdi-chevron-right
                </v-icon>
              </template>

              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  class="cursor-pointer primary--text"
                >
                  <span @click="() => setBreadcrumb(item.type, item.object, true)">
                    {{ $t('messages.views.admin.configuration.stations.' + item.type) }} :
                  </span>
                  <o-select
                    v-model="selected[item.type]"
                    :items="getBreadcrumbItems(item.type)"
                    dense
                    flat
                    solo
                    background-color="transparent"
                    hide-details
                    item-value="id"
                    style="max-width: 200px;"
                    @change="(id) => setBreadcrumb(item.type, getObjectFromId(item.type, id), true)"
                  >
                    <template v-slot:item="props">
                      {{ getBreadcrumbItemName(item.type, props.item) }}
                    </template>
                    <template v-slot:selection="props">
                      <span class="text-truncate primary--text">
                        {{ getBreadcrumbItemName(item.type, props.item) }}
                      </span>
                    </template>
                  </o-select>
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <v-divider />

            <div class="d-flex flex-grow-1">
              <v-row
                no-gutters
                class="fill-height"
              >
                <v-col class="d-flex position-relative">
                  <perfect-scrollbar class="absolute-scrollbar">
                    <v-container>
                      <component
                        :is="selectedWindow.component"
                        v-if="selectedWindow !== null"
                        ref="windowComponent"
                        v-bind="selectedWindow.attrs"
                        v-on="selectedWindow.listeners"
                      />
                    </v-container>
                  </perfect-scrollbar>
                </v-col>
              </v-row>
            </div>
          </div>
          <o-loader v-else-if="loading" />
          <div
            v-else
            class="font-italic fill-height text-caption d-flex align-center justify-center"
          >
            {{ $t('messages.views.admin.configuration.stations.noStation') }}
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { getCrossroadName } from '@utils/crossroad';
  import { upperFirst } from 'lodash/string';
  import ActionControl from '@/map/controls/ActionControl';

  export default {
    components: {
      StationWindow: () => import('@/views/admin/components/configuration/stations/window/StationWindow'),
      CrossroadWindow: () => import('@/views/admin/components/configuration/stations/window/CrossroadWindow'),
      TrafficLightWindow: () => import('@/views/admin/components/configuration/stations/window/TrafficLightWindow'),
    },

    apollo: {
      stations: {
        query: require('@gql/views/admin/configuration/stations/getStations.gql'),
        client: 'stations',
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        result: function (result) {
          this.loading = result.loading;
        },
        update: function (data) {
          if (this.selected.station) {
            this.selected.station = data.getStations.find((station) => station.id === this.selected.station.id);
          } else {
            this.selected.station = data.getStations[0];
          }

          if (this.selected.crossroad) {
            this.selected.crossroad = this.selected.station.crossroads.find((crossroad) => crossroad.id === this.selected.crossroad.id);
          }
          if (this.selected.trafficLight) {
            this.selected.trafficLight = this.selected.crossroad.trafficLights.find((trafficLight) => trafficLight.id === this.selected.trafficLight.id);
          }
          if (this.selected.entryPoint) {
            this.selected.entryPoint = this.selected.crossroad.entryPoints.find((entryPoint) => entryPoint.id === this.selected.entryPoint.id);
          }
          if (this.selected.exitPoint) {
            this.selected.exitPoint = this.selected.crossroad.exitPoints.find((exitPoint) => exitPoint.id === this.selected.exitPoint.id);
          }
          if (this.selected.sensor) {
            this.selected.sensor = this.selected.station.sensors.find((sensor) => sensor.id === this.selected.sensor.id);
          }

          return data.getStations;
        },
      },
    },

    data: () => ({
      loading: true,
      selected: {
        station: null,
        crossroad: null,
        sensor: null,
        trafficLight: null,
        entryPoint: null,
        exitPoint: null,
        path: null,
      },
      show: {
        sensors: false,
        crossroads: true,
        selectedStationCrossroads: false,
      },
    }),

    computed: {
      trafficLightAntagonists () {
        const trafficLightAntagonists = [];

        if (this.selected.crossroad && this.selected.trafficLight) {
          const antagonisms = this.selected.crossroad.antagonisms.filter((antagonism) => antagonism.trafficLightA === this.selected.trafficLight.id);
          for (const antagonism of antagonisms) {
            const antagonist = this.selected.crossroad.trafficLights.find((trafficLight) => trafficLight.id === antagonism.trafficLightB);
            trafficLightAntagonists.push(antagonist);
          }
        }

        return trafficLightAntagonists;
      },

      selectedWindow () {
        if (this.selected.trafficLight !== null) {
          return {
            component: 'TrafficLightWindow',
            attrs: {
              value: this.selected.trafficLight,
              trafficLights: this.selected.crossroad.trafficLights,
              antagonisms: this.selected.crossroad.antagonisms,
            },
            listeners: {
              'traffic-light-click': (trafficLight) => this.setBreadcrumb('trafficLight', trafficLight, true),
            },
          };
        }
        if (this.selected.crossroad !== null) {
          return {
            component: 'CrossroadWindow',
            attrs: {
              value: this.selected.crossroad,
              station: this.selected.station,
            },
            listeners: {
              'traffic-light-click': (trafficLight) => this.setBreadcrumb('trafficLight', trafficLight, true),
            },
          };
        }
        if (this.selected.station !== null) {
          return {
            component: 'StationWindow',
            attrs: {
              station: this.selected.station,
            },
            listeners: {
              'crossroad-click': (crossroad) => this.setBreadcrumb('crossroad', crossroad, true),
              'station-reconfigured': this.refreshStations,
              'sensor-created': this.refreshStations,
              'sensor-updated': this.refreshStations,
              'sensor-deleted': this.refreshStations,
              'switch-tab': (tab) => {
                this.$store.commit('map/changeMode', {
                  mode: 'simple_select',
                });

                switch (tab) {
                  case 'sensors':
                  case 'counters':
                    this.show.sensors = true;
                    this.show.crossroads = false;
                    break;
                  case 'crossroads':
                    this.show.sensors = false;
                    this.show.crossroads = true;
                    this.show.selectedStationCrossroads = true;
                    break;
                  default:
                    this.show.sensors = false;
                    this.show.crossroads = true;
                    this.show.selectedStationCrossroads = false;
                    break;
                }
              },
            },
          };
        }

        return null;
      },
      breadcrumb () {
        const items = [];

        if (this.selected.station !== null) {
          items.push({
            type: 'station',
            object: this.selected.station,
            name: this.selected.station.stationWatcherName,
          });
        }
        if (this.selected.crossroad !== null) {
          items.push({
            type: 'crossroad',
            object: this.selected.crossroad,
            name: getCrossroadName(this.selected.crossroad, this.selected.station),
          });
        }
        if (this.selected.trafficLight !== null) {
          items.push({
            type: 'trafficLight',
            object: this.selected.trafficLight,
            name: this.selected.trafficLight.number,
          });
        }

        return items;
      },
    },

    watch: {
      stations: {
        handler (newValue, oldValue) {
          if (oldValue === undefined && this.$route.query.crossroadId) {
            const crossroad = this.getObjectFromId('crossroad', parseInt(this.$route.query.crossroadId));
            if (crossroad) {
              this.$nextTick(() => this.setBreadcrumb('crossroad', crossroad, true));
            }
          }
          this.updateMapFeatures();
        },
      },
      selected: {
        deep: true,
        handler () {
          this.updateMapFeatures();
        },
      },
      show: {
        deep: true,
        handler () {
          this.updateMapFeatures();
        },
      },
    },

    mounted () {
      this.$store.commit('map/reset');
    },

    created () {
      this.$bus.$on('map.feature.selected', this.onFeatureSelected);
      this.$bus.$on('map.feature.unselected', this.onFeatureUnselected);
      this.$bus.$on('map.feature.created', this.onFeatureCreated);
      this.$bus.$on('map.feature.updated', this.onFeatureUpdated);
      this.$bus.$on('map.feature.deleted', this.onFeatureDeleted);
      this.$bus.$on('map.location.set', this.onLocationSet);
    },

    destroyed () {
      this.$store.commit('map/removeAllFeatures', {});
      this.$bus.$off('map.feature.selected', this.onFeatureSelected);
      this.$bus.$off('map.feature.unselected', this.onFeatureUnselected);
      this.$bus.$off('map.feature.created', this.onFeatureCreated);
      this.$bus.$off('map.feature.updated', this.onFeatureUpdated);
      this.$bus.$off('map.feature.deleted', this.onFeatureDeleted);
      this.$bus.$off('map.location.set', this.onLocationSet);
    },

    methods: {
      updateMapFeatures () {
        const features = {
          crossroad: {
            features: [],
            extraProperties: {
              editable: true,
              draggable: true,
            },
          },
          trafficLight: {
            features: [],
            extraProperties: {
              editable: true,
              draggable: true,
            },
          },
          entryPoint: {
            features: [],
            extraProperties: {
              editable: true,
              draggable: true,
            },
          },
          exitPoint: {
            features: [],
            extraProperties: {
              editable: true,
              draggable: true,
            },
          },
          path: {
            features: [],
            extraProperties: {
              editable: true,
              draggable: false,
            },
          },
          sensor: {
            features: [],
            extraProperties: {
              editable: true,
              draggable: true,
            },
          },
          trafficLightControl: {
            features: [],
            extraProperties: {
              clickable: false,
              editable: false,
              draggable: false,
              selectable: false,
            },
          },
          entryPointDataSource: {
            features: [],
            extraProperties: {
              clickable: false,
              editable: false,
              draggable: false,
              selectable: false,
            },
          },
        };

        if (this.stations) {
          // Sensors
          if (this.selected.station && (this.selected.crossroad || this.show.sensors)) {
            features.sensor.features = this.selected.station.sensors;
          }

          if (this.selected.crossroad) {
            // TrafficLights
            if (this.selected.crossroad.trafficLights) {
              for (const trafficLight of this.selected.crossroad.trafficLights) {
                if (trafficLight.location) {
                  let isAntagonist = false;
                  if (this.trafficLightAntagonists !== null && this.trafficLightAntagonists.length) {
                    const antagonsist = this.trafficLightAntagonists.find((antagonist) => antagonist.id === trafficLight.id);
                    if (antagonsist) {
                      isAntagonist = true;
                    }
                  }

                  features.trafficLight.features.push({
                    ...trafficLight,
                    isAntagonist: isAntagonist,
                  });
                }
              }
            }

            // EntryPoints
            if (this.selected.crossroad.entryPoints) {
              features.entryPoint.features = this.selected.crossroad.entryPoints;
            }

            // ExitPoints
            if (this.selected.crossroad.exitPoints) {
              features.exitPoint.features = this.selected.crossroad.exitPoints;
            }

            // Paths
            if (this.selected.trafficLight) {
              for (const entryPoint of this.selected.crossroad.entryPoints) {
                const selected = this.selected.trafficLight.entryPoints.includes(entryPoint.id);
                for (const path of entryPoint.paths) {
                  features.path.features.push({
                    ...path,
                    selected: selected,
                    color: selected ? '#1976D2' : null,
                  });
                }
              }
              features.path.features.sort(path => path.selected ? 1 : -1);
            } else if (this.selected.entryPoint) {
              features.path.features = this.selected.entryPoint.paths;
            } else if (this.selected.exitPoint) {
              for (const pathId of this.selected.exitPoint.paths) {
                features.path.features.push(this.getObjectFromId('path', pathId));
              }
            } else {
              for (const entryPoint of this.selected.crossroad.entryPoints) {
                for (const path of entryPoint.paths) {
                  features.path.features.push(path);
                }
              }
            }

            // TrafficLightControls
            if (this.selected.trafficLight && this.selected.trafficLight.location) {
              for (const entryPointId of this.selected.trafficLight.entryPoints) {
                const entryPoint = this.selected.crossroad.entryPoints.find((entryPoint) => entryPoint.id === entryPointId);
                features.trafficLightControl.features.push({
                  id: this.selected.trafficLight.id + '-' + entryPoint.id,
                  trafficLight: this.selected.trafficLight,
                  entryPoint: entryPoint,
                });
              }
            }

            // EntryPointDataSources
            if (this.selected.sensor) {
              for (const entryPointId of this.selected.sensor.entryPoints) {
                const entryPoint = this.selected.crossroad.entryPoints.find((ep) => ep.id === entryPointId);
                if (entryPoint) {
                  features.entryPointDataSource.features.push({
                    id: this.selected.sensor.id + '-' + entryPoint.id,
                    sensor: this.selected.sensor,
                    entryPoint: entryPoint,
                  });
                }
              }
            } else if (this.selected.entryPoint) {
              if (this.selected.entryPoint.sensor) {
                const sensor = this.selected.station.sensors.find((ep) => ep.id === this.selected.entryPoint.sensor);
                if (sensor) {
                  features.entryPointDataSource.features.push({
                    id: sensor.id + '-' + this.selected.entryPoint.id,
                    sensor: sensor,
                    entryPoint: this.selected.entryPoint,
                  });
                }
              }
            } else {
              for (const entryPoint of this.selected.crossroad.entryPoints) {
                if (entryPoint.sensor) {
                  const sensor = this.selected.station.sensors.find((ep) => ep.id === entryPoint.sensor);
                  features.entryPointDataSource.features.push({
                    id: sensor.id + '-' + entryPoint.id,
                    sensor: sensor,
                    entryPoint: entryPoint,
                  });
                }
              }
            }
          } else {
            if (this.show.crossroads) {
              if (this.show.selectedStationCrossroads && this.selected.station) {
                for (const crossroad of this.selected.station.crossroads) {
                  if (crossroad.location) {
                    features.crossroad.features.push(crossroad);
                  }
                }
              } else {
                for (const station of this.stations) {
                  if (station.crossroads) {
                    for (const crossroad of station.crossroads) {
                      if (crossroad.location) {
                        features.crossroad.features.push(crossroad);
                      }
                    }
                  }
                }
              }
            }
          }

          for (const type of Object.keys(features)) {
            this.$store.commit('map/setFeatures', {
              type: type,
              features: features[type].features,
              extraProperties: features[type].extraProperties,
            });
          }
        }

        this.updateMapActions();
      },

      updateMapActions () {
        let actions;
        if (this.selected.trafficLight) {
          actions = [
            ActionControl.actions.LINK_ENTRY_POINT_TO_TRAFFIC_LIGHT,
            ActionControl.actions.UNLINK_ENTRY_POINT_TO_TRAFFIC_LIGHT,
          ];
        } else if (this.selected.sensor) {
          actions = [
            ActionControl.actions.LINK_ENTRY_POINT_TO_SENSOR,
          ];

          if (this.selected.sensor.entryPoints.length) {
            actions.push(ActionControl.actions.UNLINK_ENTRY_POINT_TO_SENSOR);
          }

          actions.push(
            ActionControl.actions.DELETE,
            ActionControl.actions.CANCEL
          );
        } else if (this.selected.entryPoint) {
          actions = [
            ActionControl.actions.CREATE_PATH,
          ];

          if (!this.selected.entryPoint.sensor) {
            actions.push(ActionControl.actions.LINK_ENTRY_POINT_TO_SENSOR);
          } else {
            actions.push(ActionControl.actions.UNLINK_ENTRY_POINT_TO_SENSOR);
          }

          actions.push(
            ActionControl.actions.DELETE,
            ActionControl.actions.CANCEL
          );
        } else if (this.selected.exitPoint) {
          actions = [
            ActionControl.actions.CREATE_PATH,
            ActionControl.actions.DELETE,
            ActionControl.actions.CANCEL,
          ];
        } else if (this.selected.path) {
          actions = [
            ActionControl.actions.DELETE,
            ActionControl.actions.CANCEL,
          ];
        } else if (this.selected.crossroad) {
          actions = [
            ActionControl.actions.CREATE_ENTRY_POINT,
            ActionControl.actions.CREATE_EXIT_POINT,
          ];
        } else if (this.selected.station) {
          actions = [];
          if (!this.selected.crossroad && this.show.sensors) {
            actions.push(ActionControl.actions.CREATE_SENSOR);
          }
        } else {
          actions = [];
        }

        this.$store.commit('map/setDefaultActions', actions);
      },

      onFeatureSelected ({ type, id }) {
        this.setBreadcrumb(type, this.getObjectFromId(type, id), false);
      },

      onFeatureUnselected ({ type, id }) {
        switch (type) {
          case 'trafficLight':
          case 'entryPoint':
          case 'exitPoint':
          case 'sensor':
          case 'path':
            if (this.selected.crossroad) {
              this.setBreadcrumb('crossroad', this.selected.crossroad, false, false);
            } else {
              this.setBreadcrumb('station', this.selected.station, false, false);
            }
            break;
        }
      },

      onFeatureCreated ({ id, type, feature }) {
        switch (type) {
          case 'entryPoint':
          case 'exitPoint':
            this.loading = true;
            const mutation = require('@gql/mutations/' + type + '/create' + upperFirst(type) + '.gql');

            this.$apollo.mutate({
              mutation: mutation,
              client: 'stations',
              fetchPolicy: 'no-cache',
              variables: {
                input: {
                  crossroad: this.selected.crossroad.id,
                  location: feature.location,
                },
              },
            }).then(() => {
              this.refreshStations();
            }).catch((e) => {
              this.$flash(null, 'error');
              this.loading = false;
            });
            break;
          case 'sensor':
            this.$refs.windowComponent.onSensorCreate(feature.location);
            break;
          case 'trafficLightControl':
            this.loading = true;
            this.$apollo.mutate({
              mutation: require('@gql/mutations/trafficLight/addEntryPointToTrafficLight.gql'),
              client: 'stations',
              fetchPolicy: 'no-cache',
              variables: {
                input: {
                  trafficLight: feature.trafficLightId,
                  entryPoint: feature.entryPointId,
                },
              },
            }).then(() => {
              this.refreshStations();
            }).catch((e) => {
              this.$flash(null, 'error');
              this.loading = false;
            });
            break;
          case 'entryPointDataSource':
            this.loading = true;
            this.$apollo.mutate({
              mutation: require('@gql/mutations/entryPoint/updateEntryPointSensor.gql'),
              client: 'stations',
              fetchPolicy: 'no-cache',
              variables: {
                input: {
                  id: feature.entryPointId,
                  sensor: feature.sensorId,
                },
              },
            }).then(() => {
              this.refreshStations();
            }).catch((e) => {
              this.$flash(null, 'error');
              this.loading = false;
            });
            break;
          case 'path':
            this.loading = true;
            this.$apollo.mutate({
              mutation: require('@gql/mutations/path/createPath.gql'),
              client: 'stations',
              fetchPolicy: 'no-cache',
              variables: {
                input: {
                  entryPoint: feature.entryPointId,
                  exitPoint: feature.exitPointId,
                },
              },
            }).then(() => {
              this.refreshStations();
            }).catch((e) => {
              this.$flash(null, 'error');
              this.loading = false;
            });
            break;
        }
      },

      onFeatureUpdated ({ id, type, feature }) {
        const object = this.getObjectFromId(type, id);

        switch (type) {
          case 'crossroad':
          case 'trafficLight':
          case 'entryPoint':
          case 'exitPoint':
            this.loading = true;
            const mutation = require('@gql/mutations/' + type + '/update' + upperFirst(type) + 'Location.gql');

            this.$apollo.mutate({
              mutation: mutation,
              client: 'stations',
              fetchPolicy: 'no-cache',
              variables: {
                input: {
                  id,
                  location: feature.location,
                },
              },
            }).then(() => {
              this.refreshStations();
            }).catch((e) => {
              this.$flash(null, 'error');
              this.loading = false;
            });
            break;
          case 'sensor':
            object.location = feature.location;

            this.$apollo.mutate({
              mutation: require('@gql/mutations/sensor/updateSensor.gql'),
              client: 'stations',
              fetchPolicy: 'no-cache',
              variables: {
                input: object,
              },
            }).catch((e) => {
              this.$flash(null, 'error');
            });
            break;
          case 'path':
            object.geometry = feature.geometry;
            this.$apollo.mutate({
              mutation: require('@gql/mutations/path/updatePathGeometry.gql'),
              client: 'stations',
              fetchPolicy: 'no-cache',
              variables: {
                input: {
                  id: id,
                  geometry: feature.geometry,
                },
              },
            }).catch((e) => {
              this.$flash('messages.views.admin.configuration.stations.updatePathGeometry.error', 'error');
            });
            break;
        }
      },

      onFeatureDeleted ({ id, type, feature }) {
        switch (type) {
          case 'sensor':
          case 'entryPoint':
          case 'exitPoint':
          case 'path':
            this.loading = true;
            const object = this.getObjectFromId(type, id);
            const mutation = require('@gql/mutations/' + type + '/delete' + upperFirst(type) + '.gql');

            this.$apollo.mutate({
              mutation: mutation,
              client: 'stations',
              fetchPolicy: 'no-cache',
              variables: {
                id: object.id,
              },
            }).then(() => {
              this.refreshStations();
              switch (type) {
                case 'sensor':
                  this.setBreadcrumb('station', this.selected.station, false);
                  break;
                case 'entryPoint':
                case 'exitPoint':
                case 'path':
                  this.setBreadcrumb('crossroad', this.selected.crossroad, false);
                  break;
              }
            }).catch((e) => {
              this.$flash(null, 'error');
              this.loading = false;
            });
            break;
          case 'trafficLightControl':
            const trafficLight = this.selected.crossroad.trafficLights.find((trafficLight) => trafficLight.id === feature.trafficLightId);

            if (!trafficLight.entryPoints.find((entryPointId) => feature.entryPointId === entryPointId)) {
              this.$flash(this.$t('views.admin.components.dashboard.stations.entryPointNotDefined'), 'error');
            } else {
              this.loading = true;
              this.$apollo.mutate({
                mutation: require('@gql/mutations/trafficLight/removeEntryPointFromTrafficLight.gql'),
                client: 'stations',
                fetchPolicy: 'no-cache',
                variables: {
                  input: {
                    trafficLight: feature.trafficLightId,
                    entryPoint: feature.entryPointId,
                  },
                },
              }).then(() => {
                this.refreshStations();
              }).catch((e) => {
                this.$flash(null, 'error');
                this.loading = false;
              });
            }
            break;
          case 'entryPointDataSource':
            this.loading = true;
            this.$apollo.mutate({
              mutation: require('@gql/mutations/entryPoint/updateEntryPointSensor.gql'),
              client: 'stations',
              fetchPolicy: 'no-cache',
              variables: {
                input: {
                  id: feature.entryPointId,
                  sensor: null,
                },
              },
            }).then(() => {
              this.refreshStations();
            }).catch((e) => {
              this.$flash(null, 'error');
              this.loading = false;
            });
            break;
        }
      },

      onLocationSet (location) {
        this.loading = true;

        let mutation, input, type;
        if (this.selected.trafficLight) {
          type = 'trafficLight';
          mutation = require('@gql/mutations/trafficLight/updateTrafficLightLocation.gql');
          input = {
            id: this.selected.trafficLight.id,
            location: location,
          };
        } else if (this.selected.crossroad) {
          type = 'crossroad';
          mutation = require('@gql/mutations/crossroad/updateCrossroadLocation.gql');
          input = {
            id: this.selected.crossroad.id,
            location: location,
          };
        } else {
          return;
        }

        this.$apollo.mutate({
          mutation: mutation,
          client: 'stations',
          fetchPolicy: 'no-cache',
          variables: {
            input: input,
          },
        }).then(() => {
          this.refreshStations();
          if (type === 'crossroad') {
            this.flyToCrossroad({ location: location });
          }
        }).catch((e) => {
          this.$flash(null, 'error');
          this.loading = false;
        });
      },

      getObjectFromId (type, id) {
        switch (type) {
          case 'station':
            return this.stations.find((station) => station.id === id);
          case 'crossroad':
            for (const station of this.stations) {
              const crossroad = station.crossroads.find((crossroad) => crossroad.id === id);
              if (crossroad !== undefined) {
                return crossroad;
              }
            }
            break;
          case 'trafficLight':
            for (const station of this.stations) {
              for (const crossroad of station.crossroads) {
                const trafficLight = crossroad.trafficLights.find((trafficLight) => trafficLight.id === id);
                if (trafficLight !== undefined) {
                  return trafficLight;
                }
              }
            }
            break;
          case 'entryPoint':
            for (const station of this.stations) {
              for (const crossroad of station.crossroads) {
                const entryPoint = crossroad.entryPoints.find((entryPoint) => entryPoint.id === id);
                if (entryPoint !== undefined) {
                  return entryPoint;
                }
              }
            }
            break;
          case 'exitPoint':
            for (const station of this.stations) {
              for (const crossroad of station.crossroads) {
                const exitPoint = crossroad.exitPoints.find((exitPoint) => exitPoint.id === id);
                if (exitPoint !== undefined) {
                  return exitPoint;
                }
              }
            }
            break;
          case 'path':
            for (const station of this.stations) {
              for (const crossroad of station.crossroads) {
                for (const entryPoint of crossroad.entryPoints) {
                  const path = entryPoint.paths.find((path) => path.id === id);
                  if (path !== undefined) {
                    return path;
                  }
                }
              }
            }
            break;
          case 'sensor':
            for (const station of this.stations) {
              const sensor = station.sensors.find((sensor) => sensor.id === id);
              if (sensor !== undefined) {
                return sensor;
              }
            }
            break;
        }

        return null;
      },

      setBreadcrumb (type, object, select, fly) {
        if (typeof object !== 'object') {
          object = this.getObjectFromId(type, object);
        }

        switch (type) {
          case null:
            this.selected.station = null;
            this.selected.crossroad = null;
            this.selected.sensor = null;
            this.selected.trafficLight = null;
            this.selected.entryPoint = null;
            this.selected.exitPoint = null;
            this.selected.path = null;
            break;
          case 'station':
            this.selected.station = object;
            this.selected.crossroad = null;
            this.selected.sensor = null;
            this.selected.trafficLight = null;
            this.selected.entryPoint = null;
            this.selected.exitPoint = null;
            this.selected.path = null;
            break;
          case 'crossroad':
            this.selected.station = this.getObjectFromId('station', object.station.id);
            this.selected.crossroad = object;
            this.selected.sensor = null;
            this.selected.trafficLight = null;
            this.selected.entryPoint = null;
            this.selected.exitPoint = null;
            this.selected.path = null;

            if (object.location === null) {
              return this.$store.commit('map/changeMode', {
                mode: 'set_location',
              });
            } else if (fly === undefined || fly) {
              this.flyToCrossroad(object);
            }
            break;
          case 'sensor':
            this.selected.sensor = object;
            break;
          case 'trafficLight':
            this.selected.sensor = null;
            this.selected.trafficLight = object;
            this.selected.entryPoint = null;
            this.selected.exitPoint = null;
            this.selected.path = null;

            if (object.location === null) {
              return this.$store.commit('map/changeMode', {
                mode: 'set_location',
              });
            }
            break;
          case 'entryPoint':
            this.selected.sensor = null;
            this.selected.trafficLight = null;
            this.selected.entryPoint = object;
            this.selected.exitPoint = null;
            this.selected.path = null;
            break;
          case 'exitPoint':
            this.selected.sensor = null;
            this.selected.trafficLight = null;
            this.selected.entryPoint = null;
            this.selected.exitPoint = object;
            this.selected.path = null;
            break;
          case 'path':
            this.selected.sensor = null;
            this.selected.trafficLight = null;
            this.selected.entryPoint = null;
            this.selected.exitPoint = null;
            this.selected.path = object;
            break;
        }

        if (type !== null) {
          if (select === undefined || select) {
            this.$store.commit('map/selectFeature', {
              type: type,
              id: object.id,
            });
          } else {
            this.$store.commit('map/changeMode', {
              mode: 'simple_select',
            });
          }
        }
      },

      refreshStations () {
        this.$apollo.queries.stations.refresh();
      },

      flyToCrossroad (object) {
        if (object.location === null) {
          const boundaries = [];
          for (const trafficLight of object.trafficLights) {
            if (trafficLight.location !== null) {
              boundaries.push(trafficLight.location);
            }
          }
          for (const entryPoint of object.entryPoints) {
            boundaries.push(entryPoint.location);
          }
          for (const exitPoint of object.exitPoints) {
            boundaries.push(exitPoint.location);
          }

          if (boundaries.length >= 2) {
            this.$store.commit('map/flyTo', {
              location: boundaries,
              speed: 2,
            });
          } else {
            this.$store.commit('map/flyTo', {
              location: object.location,
              zoom: 19,
              speed: 2,
            });
          }
        } else {
          this.$store.commit('map/flyTo', {
            location: object.location,
            zoom: 19,
            speed: 2,
          });
        }
      },

      getBreadcrumbItems (type) {
        switch (type) {
          case 'station':
            return this.stations;
          case 'crossroad':
            return this.selected.station.crossroads;
          case 'trafficLight':
            return this.selected.crossroad.trafficLights;
        }

        return [];
      },

      getBreadcrumbItemName (type, item) {
        switch (type) {
          case 'station':
            return item.stationWatcherName;
          case 'crossroad':
            return getCrossroadName(item);
          case 'trafficLight':
            return item.name ? item.name : item.number;
        }

        return 'null';
      },
    },
  };
</script>

<style lang="sass" scoped>
  .v-tab
    text-transform: none !important
  .station-tabs
    max-width: 200px !important
  .station-tab
    max-width: 168px !important
</style>
